<template>

    <div>
        <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('Sparepart')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
            <div class="flex gap-2">
                <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'sparepart-report' })">
                    Kembali
                </vs-button>
                <vs-button color="warning"  icon="edit" type="border" @click="$router.push({ name: 'sparepart-edit' })">
                    Edit
                </vs-button>
            </div>
        </template>
        <h6 class="font-bold mb-1">Title : <span class="font-medium">{{ item.title }}</span></h6>
        <h6 class="font-bold mb-1">Category : <span class="font-medium">{{ item.category.category_name }}</span></h6>
        <h6 class="font-bold mb-1">Brand : <span class="font-medium">{{ item.brand.brand_name }}</span></h6>
        <div v-for="(attr, index) in item.attribute" :key="index">
            <h6 class="font-bold mb-1 capitalize">{{ attr.key }} : <span class="font-medium">{{ attr.value }}</span></h6>
        </div>
        <div class="vx-row mt-5">
            <div class="vx-col w-full lg:w-1/2">
            <h6 class="font-bold my-1">Sparepart In</h6>
              <div class="vx-row mt-5">
                  <div class="vx-col w-full lg:w-1/2">
                      <img :src="item.sparepart_in.picture" class="picture w-full">
                    </div>
                    <div class="vx-col w-full lg:w-1/2 my-2">
                        <h6 class="font-bold mb-1">Serial Number : <span class="font-medium">{{ item.sparepart_in.serial_number ? item.sparepart_in.serial_number : '-' }}</span></h6>
                        <h6 class="font-bold mb-1">Date : <span class="font-medium">{{ item.sparepart_in.date }}</span></h6>
                        <h6 class="font-bold mb-1">Pic : <span class="font-medium">{{ item.sparepart_in.pic }}</span></h6>
                        <div class="flex gap-2">
                            <h6 class="font-bold mb-1">Note : </h6>
                            <p>{{ item.sparepart_in.note }}</p>
                        </div>
                    </div>
            </div>
          </div>
          <div class="vx-col w-full lg:w-1/2" v-if="item.sparepart_out != null">
              <h6 class="font-bold my-5 lg:my-1">Sparepart Out</h6>
              <div class="vx-row mt-5">
                  <div class="vx-col  w-full lg:w-1/2">
                      <img :src="item.sparepart_out.picture" class="picture w-full">
                    </div>
                    <div class="vx-col  w-full lg:w-1/2 my-2">
                        <h6 class="font-bold mb-1">Serial Number : <span class="font-medium">{{ item.sparepart_out.serial_number ? item.sparepart_out.serial_number : '-' }}</span></h6>
                        <h6 class="font-bold mb-1">Date : <span class="font-medium">{{ item.sparepart_out.date }}</span></h6>
                        <h6 class="font-bold mb-1">Pic : <span class="font-medium">{{ item.sparepart_out.pic }}</span></h6>
                        <div class="flex gap-2">
                            <h6 class="font-bold mb-1">Note : </h6>
                            <p>{{ item.sparepart_out.note }}</p>
                        </div>
                    </div>
            </div>
          </div>
        </div>


      </vx-card>

    </div>
  </div>
    </div>
  
  </template>
  
  <script>
import storage from '@/storage.js'
import moment from 'moment'

  
  
  export default {
    name: 'ShowSparepartVue',
    computed: {
        encrypt () {
        return this.$secure.encrypt(this.id);
        },

        isDetail(){
        if(this.$route.params.id){
            return true;
        }
        return false;
        }
    },
    data() {
        return {
            id: '',
            item: []
        }
    },
    methods: {
        getData(){
            this.$store.dispatch("sparepart/showSparepart", this.id)
            .then((resp) => {
                this.item = resp.data.values
                this.item.sparepart_in.picture = storage.sparepart + this.item.sparepart_in.picture
                this.item.sparepart_in.date = moment(this.item.sparepart_in.date).format("DD MMM YYYY HH:mm [WIB]")
                if (this.item.sparepart_out) {
                    this.item.sparepart_out.picture = storage.sparepart + this.item.sparepart_out.picture
                    this.item.sparepart_out.date = moment(this.item.sparepart_out.date).format("DD MMM YYYY HH:mm [WIB]")
                }
            }).catch(
                err => {
                    this.$vs.notify({
                        title:'Opps something error',
                        text: err.data.message,
                        color:'danger'
                        })
                }
            )
        }
    },
    created () {
      if(this.$route.params.id){
        this.id = this.$secure.decrypt(this.$route.params.id);
      }
      this.getData();
    }
  }
  
  </script>
  
  <style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  .picture {
  width: 200px;
  height: 250px;
}
  </style>
  